<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  /**
   * Form-element component
   */
  export default {
    page: {
      title: "母公司上传",
      meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader },
    data() {
      return {
        title: "母公司上传",
        items: [
          {
            text: "首页",
            href: "/"
          },
          {
            text: "母公司上传",
            active: true
          }
        ],
        status: "not_accepted",
        checkCustom: "not_accepted",
        checked: true,
        activeName: '地产',
        value: true,
        FormData: {
          // 集团名称
          group_name: "",
          // 票系
          piaoxi: "",
          // 行业类别
          leib: '地产',
          // 是否上市
          Listed_of: true,
          // 上市编号
          Listing_number: "",
          // 文件file
          paper_file: "",
          // Logofile
          Logo_file: "",
          enterprise: "民营",
          guojpj: "",
          guonpj: "",
        },
        loading: false
      };
    },
    mounted () {
      console.log(this.$refs)
    } ,
    methods: {
      // LOGO上传成功后清空
      LogofileSuccess() {
        this.$refs.LogoUpload.clearFiles()
      },
      // 文件上传成功后清空
      fileSuccess(response, file, fileList) {
        this.$refs.upload.clearFiles()
      },
      // LOGO上传前获取文件file
      LogohttpRequest(param) {
        this.FormData.Logo_file = param.file
      },
      // 文件上传前获取文件file
      httpRequest(param) {
        this.FormData.paper_file = param.file
      },
      // 删除文件列表
      handleRemove() {
        this.$refs.upload.clearFiles()
      },
      //移除上一个文件
      removeLastFile(files, fileList) {
        if (fileList) {
          this.filesUpload = fileList.slice(-1)
        }
      },
      // 提交上传
      submitUpload() {
        let that = this
        this.$refs.upload.submit();
        this.$refs.LogoUpload.submit();
        this.loading = true
        let Listed_of = this.FormData.Listing_number == '' ? 0 : 1
        let fd = new FormData()
        fd.append('upload', this.FormData.paper_file)
        fd.append('log', this.FormData.Logo_file)
        fd.append('Listing_number', this.FormData.Listing_number)
        fd.append('Listed_of', Listed_of)
        fd.append('leib', this.FormData.leib)
        fd.append('piaoxi', this.FormData.piaoxi)
        fd.append('group_name', this.FormData.group_name)
        fd.append('enterprise', this.FormData.enterprise)
        fd.append('guojpj', this.FormData.guojpj)
        fd.append('guonpj', this.FormData.guonpj)
        if (this.loading) {
          _API_.ParentCompanyUpload(fd)
            .then((res) => {
              if (res.code == 200) {
                let that = this
                this.loading = false
                this.FormData = {
                  // 集团名称
                  group_name: "",
                  // 票系
                  piaoxi: "",
                  // 行业类别
                  leib: '地产',
                  // 是否上市
                  Listed_of: true,
                  // 上市编号
                  Listing_number: "",
                  // 文件file
                  paper_file: "",
                  // Logofile
                  Logo_file: "",
                  enterprise: "民营",
                  guojpj: "",
                  guonpj: "",
                }
                utils.ShowMsg(res.msg,'success',()=>{
                  this.$refs.upload.clearFiles()
                  this.$refs.LogoUpload.clearFiles()
                })
              }else
              {
                utils.ShowMsg(res,'error')
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      }
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <!-- end row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="padding: 20px 0;">
          <!-- 文件上传 -->
          <div style="display: flex;align-items: center;justify-content: center;">
            <div style="width: 100px;">
              文件上传：
            </div>
            <el-upload ref="upload" class="upload-demo" :on-success="fileSuccess" :handleRemove="handleRemove"
              :on-change="removeLastFile" :limit="1" :auto-upload="false" :http-request="httpRequest" drag
              action="https://jsonplaceholder.typicode.com/posts/" multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
          </div>

          <!-- LOGO上传 -->
          <div style="display: flex;align-items: center;justify-content: center;margin-top:10px;">
            <div style="width: 100px;">
              LOGO上传：
            </div>
            <el-upload class="upload-demo" :auto-upload="false" ref="LogoUpload" :limit="1"
              :on-success="LogofileSuccess" :http-request="LogohttpRequest" drag
              action="https://jsonplaceholder.typicode.com/posts/" multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>

          </div>

          <!-- 集团名称 -->
          <div style="display: flex;align-items: center;justify-content: center;margin-top:10px;">
            <div style="width: 100px;">
              集团：
            </div>
            <el-input style="width: 360px;" v-model="FormData.group_name" placeholder="请输入内容"></el-input>
          </div>

          <div style="display: flex;align-items: center;justify-content: center;margin-top:10px;">
            <div style="width: 100px;">
              上市编号：
            </div>
            <el-input style="width: 360px;" v-model="FormData.Listing_number" placeholder="请输入内容"></el-input>
          </div>

          <div style="display: flex;align-items: center;justify-content: center;margin-top:10px;">
            <div style="width: 100px;">
              企业性质：
            </div>
            <!-- 企业性质： -->
            <el-tabs style="width: 360px;height: 40px;" v-model="FormData.enterprise" type="card">
              <el-tab-pane label="民营" name="民营"></el-tab-pane>
              <el-tab-pane label="央企" name="央企"></el-tab-pane>
              <el-tab-pane label="国企" name="国企"></el-tab-pane>
              <el-tab-pane label="外资" name="外资"></el-tab-pane>
              <el-tab-pane label="其他" name="其他"></el-tab-pane>
            </el-tabs>
          </div>



          <div style="display: flex;align-items: center;justify-content: center;margin-top:10px;">
            <div style="width: 100px;">
              国际评级：
            </div>
            <el-input style="width: 360px;" v-model="FormData.guojpj" placeholder="请输入内容"></el-input>
          </div>

          <div style="display: flex;align-items: center;justify-content: center;margin-top:10px;">
            <div style="width: 100px;">
              国内评级：
            </div>
            <el-input style="width: 360px;" v-model="FormData.guonpj" placeholder="请输入内容"></el-input>
          </div>

          <div style="display: flex;align-items: center;justify-content: center;margin-top:10px;">
            <div style="width: 100px;">
              行业类别：
            </div>
            <!-- 票系分类 -->
            <el-tabs style="width: 360px;height: 40px;" v-model="FormData.leib" type="card">
              <el-tab-pane style="    padding: 0 21.6px;" label="地产" name="地产"></el-tab-pane>
              <el-tab-pane label="建筑" name="建筑"></el-tab-pane>
              <el-tab-pane label="大型" name="大型"></el-tab-pane>
              <el-tab-pane label="银票" name="银票"></el-tab-pane>
              <el-tab-pane label="其他" name="其他"></el-tab-pane>
            </el-tabs>
          </div>
          <div style="display: flex;align-items: center;justify-content: center;margin-top:10px;">
            <div style="width: 100px;">
              票系：
            </div>
            <el-input style="width: 360px;" v-model="FormData.piaoxi" placeholder="请输入内容"></el-input>
          </div>

          <div style="display: flex;align-items: center;justify-content: center;margin-top:10px;">
            <el-button @click="submitUpload" type="success">提交</el-button>
          </div>



        </div>
      </div>

    </div>

    <!-- end row -->
  </Layout>
</template>